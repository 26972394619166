@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  --background-color: black;
  --primary-color: white;
  --secondary-color: gray;
  --tertiary-color: rgb(52, 52, 52);
  --curve: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: var(--primary-color);
}

html {
  font-size: 62.5%; /* Set the base font size to 10px */
  /* background-color: var(--background-color); */

  /* background-color: #68111f;
  background-image: linear-gradient(180deg, #000000 0%, #68111f 100%); */

  background-color: var(--background-color);
  background-size: cover;
  background-repeat: no-repeat;


  height: 100%;
}

body {
  font-size: 1.6rem; /* Equivalent to 16px */
}

.number {
  font-size: 5rem;
}

h1 {
  font-size: 3.2rem; /* Equivalent to 32px */
}

h2 {
  font-size: 2.4rem; /* Equivalent to 24px */
}

h3 {
  font-size: 2rem; /* Equivalent to 20px */
}

p {
  font-size: 1.6rem; /* Equivalent to 16px */
}

small {
  font-size: 1.4rem; /* Equivalent to 14px */
}

.button-6 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.App {
  width: 100%;
  height: 100%;

  /* grid layout */
  display: flex;
  flex-direction: column;
  
}

.Page {
  width: 100vw;
  height: calc(100dvh - 85px);
  position: relative;
  top: 85px;
  padding: 0 20px;
}
.HomePage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.UserCard {
  width: 100%;
  background-color: var(--tertiary-color);
  padding: 20px;
  border-radius: var(--curve);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.UserCard .photo {
  border-radius: 50%;
}

.Cards {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 15vh;
}

.Card {
  width: 100%;
  min-height: 100px;
  background-color: var(--tertiary-color);
  padding: 20px 5px;
  border-radius: var(--curve);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
}

button {
  width: 100%;
  background-color: var(--tertiary-color);
  height: 100px;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 2rem;
}

.last-words-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.last-words {
  display: flex;
  flex-direction: column;
  color: white;
  list-style: none;
  gap: 10px;
  height: 70dvh;
  overflow-y: scroll;
}

.last-word-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--tertiary-color);
  height: 100%;
  max-height: 100px;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.author {
  text-align: right;
  color: var(--secondary-color);
}

.footer {
  height: 10dvh;
}

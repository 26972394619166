.LoginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login {
  display: flex;
  flex-direction: column;
  width: 100vw;

  justify-content: center;
  align-items: center;
}

.Login .google {
  width: 200px;
  object-fit: cover;
  aspect-ratio: 1/1;

  background-color: var(--tertiary-color);
  border-radius: 50%;
}

.sign-in {
  width: 200px;
  margin-top: 10px;
  height: 50px;
  padding: 0;

}